import React from "react"
import "./../../assets/sass/slides.scss"
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  Button,
  Row,
  Col,
} from 'reactstrap'
import OfficeWeb from './../../assets/images/Office.webp'



class OfficeSlide extends React.Component {

  navigate = () => {
    window.location.href = process.env.REACT_APP_URL_OFFICE
  }

  render() {
    const t = this.props.t

    return (
      <div className="container-fluid">
        <Row className="title">
          <span>{t("slides.office")}</span>
        </Row>
        <Row className="office-row">
          <picture>
            <img src={OfficeWeb} />
          </picture>
          <span>{t("span.office")}</span>
        </Row>
        <Row className="button-row">
          <button className="btn-off" onClick={() => this.navigate()}>{t("button.btn-demo")}</button>
        </Row>
      </div>
    )
  }
}

export default OfficeSlide