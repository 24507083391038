import React from 'react';
import PageSwitcher from './views/PageSwitcher'
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import './App.css';

function App() {
  return (
    <div>
      <ToastContainer
        position="bottom-left"/>
      <PageSwitcher />
    </div>
  );
}

export default App;
