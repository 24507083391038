import React from "react"
import "./../../assets/sass/slides.scss"
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  Button,
  Row,
  Col,
} from 'reactstrap'
import MobileWeb from './../../assets/images/Mobile.webp'
import QRCode from 'react-qr-code'
import scssVars from './../../assets/sass/vars.scss'



class MobileSlide extends React.Component {
  render() {
    const t = this.props.t

    return (
      <div className="container-fluid">
        <Row className="title">
          <span>{t("slides.mobile")}</span>
        </Row>
        <Row className="mobile-row">
          <picture>
            <img src={MobileWeb} />
          </picture>
          <span>{t("span.mobile.main")}</span>
          <div className="qr-code-div">
            <div className="qr-1">
              <QRCode
                value="[link: stage.demo.lokk.dev/assets/mobile]"
                size={150}
                bgColor={scssVars.blue_dark}
                fgColor='white'
                level='L'

              />
              <span>{t("span.mobile.qr-1")}</span>
            </div>
            <div className="qr-2">
              <QRCode
                value="[link: mobile.demo.lokk.dev]"
                size={150}
                bgColor={scssVars.blue_dark}
                fgColor='white'
                level='L'
              />
              <span>{t("span.mobile.qr-2")}</span>
            </div>
          </div>

        </Row>
      </div>
    )
  }
}

export default MobileSlide