import React from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CarouselCaption,
  CarouselItem,
} from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../assets/css/styles.css'
import classnames from 'classnames';
import { connect } from 'react-redux'
import axios from 'axios'
import { toast } from "react-toastify"
import { withTranslation } from 'react-i18next'
import { ReactComponent as DeliveryIcon } from './../assets/images/Delivery.svg'
import { ReactComponent as OfficeIcon } from './../assets/images/Office.svg'
import { ReactComponent as SharedIcon } from './../assets/images/Shared.svg'
import { ReactComponent as AssetIcon } from './../assets/images/Refound.svg'
import { ReactComponent as MobileIcon } from './../assets/images/Rental.svg'
import OfficeSlide from './../components/Slides/OfficeSlide'
import SharedSlide from './../components/Slides/SharedSlide'
import DeliverySlide from './../components/Slides/DeliverySlide'
import AssetSlide from './../components/Slides/AssetSlide'
import MobileSlide from './../components/Slides/MobileSlide'
import { changeLanguage } from './../redux/actions/i18n'


class PageSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: false,
      office: false,
      activeTab: 1,
      touchStart: 0,
      touchEnd: 0,
      hasTouchMoved: false,
      tabs: 5,
      hasMouseMoved: false,
      mouseStart: 0,
      mouseEnd: 0,
      lang: this.props.lang,
    }
  }

  componentDidMount() {
    this.setState({
      lang: this.props.lang
    })
    this.props.i18n.changeLanguage(this.state.lang)
  }


  handleTouchStart = (e) => {
    this.setState({
      hasTouchMoved: false,
      touchStart: e.targetTouches[0].clientX
    })
  }

  changeLanguage = (e, lang) => {
    e.stopPropagation()
    this.props.dispatch(changeLanguage(lang)).then(() => {
      this.props.i18n.changeLanguage(lang)
      toast.success(this.props.t("slides.change-lang"), { toastId: lang, position: toast.POSITION.TOP_LEFT },)
    })
  }


  handleTouchMove = (e) => {
    this.setState({
      hasTouchMoved: true,
      touchEnd: e.targetTouches[0].clientX
    })
  }

  handleTouchEnd = (tab) => {
    if (!this.state.hasTouchMoved) return null

    if (this.state.touchStart - this.state.touchEnd > 30) {
      //esquerda para a direita
      if (tab == this.state.tabs) {
        tab = 1
      }
      else {
        tab++
      }
      this.toggle(tab)
    }

    if (this.state.touchStart - this.state.touchEnd < -30) {
      //direita para esquerda
      if (tab == 1) {
        tab = this.state.tabs
      }
      else {
        tab--
      }
      this.toggle(tab)
    }
  }

  handleMouseDown = (e) => {
    this.setState({
      hasMouseMoved: true,
      mouseStart: e.clientX
    })

  }

  handleMouseUp = (e) => {
    this.setState({
      mouseEnd: e.clientX
    })

    this.handleMouseMove(this.state.mouseStart, e.clientX, this.state.activeTab)
  }

  handleMouseMove = (start, end, tab) => {
    if (start - end > 30) {
      if (tab == this.state.tabs) {
        tab = 1
      }
      else {
        tab++
      }
      this.toggle(tab)
    }
    else if (start - end < -30) {
      if (tab == 1) {
        tab = this.state.tabs
      }
      else {
        tab--
      }
      this.toggle(tab)
    }
  }


  toggle = (tab) => {
    if (this.state.activeTab != tab) this.setState({ activeTab: tab });
  }

  orders = () => {
    this.setState({ orders: true, office: false })
  }

  office = () => {
    this.setState({ office: true, orders: false })
  }

  render() {
    const { t } = this.props

    return (
      <>
        <div
          className="dragable-div"
          dragable="true"
          onMouseUp={(e) => this.handleMouseUp(e)}
          onMouseDown={(e) => this.handleMouseDown(e)}
          onTouchStart={(e) => this.handleTouchStart(e)}
          onTouchMove={(e) => this.handleTouchMove(e)}
          onTouchEnd={() => this.handleTouchEnd(this.state.activeTab)}

        >
          <Row className="change-lang-row">
            <button className={"pt " + (this.props.lang === 'pt' ? "active" : "")} onClick={(e) => this.changeLanguage(e, 'pt')}>PT</button>
            <button className={"en " + (this.props.lang === 'en' ? "active" : "")} onClick={(e) => this.changeLanguage(e, 'en')}>EN</button>
          </Row>
          <Row className="justify-content-center footer-row">
            <Col className={classnames({ 'active-menu-item': this.state.activeTab === 1 })}
              onClick={() => { this.toggle(1); }}>
              <OfficeIcon className="lock" />
            </Col>
            <Col className={classnames({ 'active-menu-item': this.state.activeTab === 2 })}
              onClick={() => { this.toggle(2); }}>
              <SharedIcon className="shared" />
            </Col>
            <Col className={classnames({ 'active-menu-item': this.state.activeTab === 3 })}
              onClick={() => { this.toggle(3); }}>
              <DeliveryIcon className="delivery" />
            </Col>
            <Col className={classnames({ 'active-menu-item': this.state.activeTab === 4 })}
              onClick={() => { this.toggle(4); }}>
              <AssetIcon className="asset" />
            </Col>
            <Col className={classnames({ 'active-menu-item': this.state.activeTab === 5 })}
              onClick={() => { this.toggle(5); }}>
              <MobileIcon className="mobile" />
            </Col>
          </Row>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId={1}>
              <OfficeSlide t={t} />
            </TabPane>
            <TabPane tabId={2}>
              <SharedSlide t={t} />
            </TabPane>
            <TabPane tabId={3}>
              <DeliverySlide t={t} />
            </TabPane>
            <TabPane tabId={4}>
              <AssetSlide t={t} />
            </TabPane>
            <TabPane tabId={5}>
              <MobileSlide t={t} />
            </TabPane>
          </TabContent>
        </div>
      </>
    )
  }
}
const mapStateToProps = state => {
  return {
    lang: state.i18n.language
  }
}

export default connect(mapStateToProps)(withTranslation('common')(PageSwitcher))
