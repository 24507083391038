const i18nReducer = (state = { language: 'en' }, action) => {
    switch (action.type) {
        case "CHANGE_LANGUAGE":
            return { ...state, language: action.language }
        default:
            return state
    }
}

export default i18nReducer
