import React from "react"
import "./../../assets/sass/slides.scss"
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  Button,
  Row,
  Col,
} from 'reactstrap'
import AssetWeb from './../../assets/images/Assets.webp'


class AssetSlide extends React.Component {

  navigate = () => {
    window.location.href = process.env.REACT_APP_URL_ASSETS
  }

  render() {
    const t = this.props.t

    return (
      <div className="container-fluid">
        <Row className="title">
          <span>{t("slides.assets")}</span>
        </Row>
        <Row className="asset-row">
          <picture>
            <img src={AssetWeb} />
          </picture>
          <span>{t("span.assets")}</span>
        </Row>
        <Row className="button-row">
          <button className="btn-ass" onClick={() => this.navigate()}>{t("button.btn-demo")}</button>
        </Row>
      </div>
    )
  }
}

export default AssetSlide