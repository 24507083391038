import React from "react"
import "./../../assets/sass/slides.scss"
import 'bootstrap/dist/css/bootstrap.min.css'
import {
    Button,
    Row,
    Col,
} from 'reactstrap'
import SharedWeb from './../../assets/images/Shared.webp'


class AssetSlide extends React.Component {

    navigate = () => {
        window.location.href = process.env.REACT_APP_URL_SHARED
    }


    render() {
        const t = this.props.t

        return (
            <div className="container-fluid">
                <Row className="title">
                    <span>{t("slides.shared")}</span>
                </Row>
                <Row className="shared-row">
                    <picture>
                        <img src={SharedWeb} />
                    </picture>
                    <span>{t("span.shared")}</span>
                </Row>
                <Row className="button-row">
                    <button className="btn-shar" onClick={() => this.navigate()}>{t("button.btn-demo")}</button>
                </Row>
            </div>
        )
    }
}

export default AssetSlide